import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Input } from 'antd';
import React from 'react';

const { Search } = Input;

export default class MailChimpForm extends React.Component {
	constructor() {
		super()
		this.state = { email: '', result: null }
	}

	_handleSubmit = async e => {
		//e.preventDefault()
		console.log(this.state.email)
		const result = await addToMailchimp(this.state.email);
		console.log(result);
		this.setState({result: result.result})
	}

	handleChange = e => {
		this.setState({ email: e.target.value })
	}

	render() {
		return (
			<>
				<div>
					{this.state.result === 'success' && <span>Lyckades! Tack för ditt intresse</span>}
					{this.state.result === 'error' && <span>Ett fel uppstod. Kontakta support</span>}
				</div>
				<div>
					<Search
						placeholder="E-post"
						enterButton="Prenumera"
						onChange={this.handleChange}
						onSearch={this._handleSubmit}
					/>
				</div>
			</>
		);
	}
}