import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Row, Col } from 'antd';
import Mailchimp from "./mailchimp"

const Hero = () => {
  	return (
		<div className="hero">
			<div className="global-wrapper" style={{paddingTop: 20}}>
				<Row>
					<Col xs={24} sm={24} md={12} lg={12}>
						<h2>Senaste podavsnitt</h2>
						<div style={{marginRight: 30}}>
							<iframe title="Senaste podcast avsnittet" height="122" width="100%" style={{border: 'none'}} scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/player-v2/?i=qvu96-10918d4-pb&from=embed&square=1&share=1&download=1&skin=f6f6f6&btn-skin=8bbb4e&size=300"></iframe>		
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<h2>Nyhetsbrev</h2>
						<p>Några gånger i månaden skickar vi ut ett nyhetsbrev (mail) med senaste recepten, blogposterna, länk till podcastavsnitt och snabba tips & trix. Registrera dig nedan för att inte missa det senaste från EverHealth!</p>
						<Mailchimp />
					</Col>
				</Row>
			</div>
		</div>	
  	);
}

export default Hero;
